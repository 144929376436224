import Navbar from "../../../components/navbar/Navbar";
import Sidebar from "../../../components/sidebar/Sidebar";
import RatingWidget from "../../../widgets/RatingWidget";
import "./MeetingRoomDetails.css";
import MeetingGif from "../../../assets/gif/conferenceroom.gif";
import { useEffect, useMemo, useState } from "react";
import CoachReviewDialog from "../../../dialogs/CoachReviewDialog";
import { ReactComponent as GoLeft } from "../../../assets/svg/GoLeft.svg";
import { ReactComponent as GoRight } from "../../../assets/svg/GoRight.svg";
import { ReactComponent as LeftRespArrow } from "../../../assets/svg/LeftRespArrow.svg";
import { ReactComponent as RightRespArrow } from "../../../assets/svg/RightRespArrow.svg";
import StarRating from "../../../widgets/StarRating";
import MeetingRoom from "../../../assets/png/MeetingRoom.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import RespReviewDialog from "../../../dialogs/profile-ui/RespReviewDialog";
import MNavbar from "../../../components/navbar/mobile/MNavbar";
import ArrowRight from "./svg/ArrowRight";
import ArrowLeft from "./svg/ArrowLeft";
import MeetingReviewDialog from "../../../dialogs/MeetingReviewDialog";
import { useParams } from "react-router-dom";
import CustomerCard from "../../../cards/CustomerCard";
import { useNavigate } from "react-router-dom";
import CustomerWidget from "../../../widgets/CustomerWidget/CustomerWidget";
import {
  extractAllValues,
  extractFirstValue,
} from "../../../utils/utilityFunctions";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
const apiURL = process.env.REACT_APP_API_URL;

const MeetingRoomDetails = () => {
  const [reviewOpen, setReviewOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleReviewOpen = () => setReviewOpen(true);
  const handleReviewClose = () => setReviewOpen(false);
  const [allReviews, setAllReviews] = useState(null);
  const [userReviewed, setUserReviewed] = useState(false);
  const [userBookings, setUserBookings] = useState();

  let navigate = useNavigate();

  const { roomId } = useParams();

  const getFileUrl = `${window.env_url}/v1/files/`;

  const [currentCustomer, setCurrentCustomer] = useState(1);

  const [customerMetaData, setCustomerMetaData] = useState(null);

  const [roomData, setRoomData] = useState({
    title: "Lorem Ipsum",
    credits: 420,
    description:
      "publishing and graphic design, Lorem ipsum is a placeho tcommonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholderbes available.publishing and graphic design, Lorem ipsum is a placeholder te",
    files: "../../../assets/png/Coach Picture.png",
  });

  const [width, setWidth] = useState(window.innerWidth);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  const MCustomerCard = ({ id, imgurl, name, description }) => {
    return (
      <div className="coach-card custR-mv" key={id}>
        <img src={imgurl} className="coach-img custR" />
        <div className="customer-rating">
          <StarRating rating={4} totalStars={5} fontSize="5vw" />
        </div>
        <div className="customer-details">
          <span className="cust-name">
            {name}
            {id}
          </span>
          <span>{description}</span>
        </div>
      </div>
    );
  };

  const leftClickHandler = () => {
    if (currentCustomer !== 1) {
      setCurrentCustomer((prev) => prev - 1);
    }
  };
  const rightClickHandler = () => {
    if (currentCustomer !== 4) {
      setCurrentCustomer((prev) => prev + 1);
    }
  };

  if (width <= 769 && width >= 1023) {
    var carouselItems = document.querySelectorAll(
      ".thumbs-wrapper.axis-vertical ul li"
    );

    carouselItems.forEach(function (item) {
      item.style.width = "174px";
    });
  }

  const arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "50%", // Adjust as needed
    transform: "translateY(-50%)", // Center vertically
  };

  const fetchMeetingBookingByUser = async () => {
    const apiUrl = `${window.env_url}/v1/meetingbookings/${roomId}?user=true`;
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP Status error! ${response.status}`);
      }
      const data = await response.json();
      setUserBookings(data?.body[0] || []);
    } catch (error) {
      console.error(error);
    }
  };

  console.log("reviewOpen", reviewOpen);
  useEffect(() => {
    fetchRoomData();
    getAllReviewsByRoom();
    fetchMeetingBookingByUser();
  }, []);

  const token = localStorage.getItem("accessToken");

  const fetchRoomData = async () => {
    const apiUrl = `${window.env_url}/v1/rooms/${roomId}`;
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP Status error! ${response.status}`);
      }
      const data = await response.json();
      setRoomData(data.body[0]);
    } catch (error) {
      console.error(error);
    }
  };

  const roomName = extractFirstValue(roomData?.title, "|");

  const handleRoomBooking = (roomId, roomName) => {
    navigate(`/booking-schedule/${roomId}`, { state: { roomName, roomData } });
  };

  const imagesArray = extractAllValues(roomData?.files, `|`);
  const meetingRoomImgs = imagesArray?.map((item) => {
    return getFileUrl + item;
  });

  const titleData = extractAllValues(roomData?.title, "|");
  const capacity = titleData[1];
  console.log(capacity);

  const getAllReviewsByRoom = async () => {
    const token = localStorage.getItem("accessToken");
    const userId = jwtDecode(token).id;
    try {
      const response = await fetch(
        `${window.env_url}/v1/reviews/room/${roomId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setAllReviews(data.body[0]);
      setCustomerMetaData(data.body[0]);
      setUserReviewed(data.body[0].some((review) => review.userId === userId));
    } catch (error) {
      console.error(error.message);
    }
  };

  const ratings = allReviews?.map((review) => {
    return review.rating;
  });
  const sum = ratings?.reduce((acc, rating) => acc + rating, 0);
  const average = sum / ratings?.length;

  const ratingFilter = [0, 0, 0, 0, 0];
  allReviews?.forEach((review) => {
    if (review.rating >= 1 && review.rating <= 5) {
      ratingFilter[review.rating - 1]++;
    }
    return review.rating;
  });

  console.log("userReviewed", userReviewed);
  return (
    <div className="mrd mr-root">
      {width <= 768 ? (
        <>
          <MNavbar />
          <div className="mrd-page-container">
            <div className="carousel-container">
              <Carousel
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <LeftRespArrow
                      onClick={onClickHandler}
                      title={label}
                      style={{ ...arrowStyles, left: "11px", width: "27px" }}
                    />
                  )
                }
                renderArrowNext={(onClickHandler, hasNext, hasPrev, label) =>
                  hasNext && (
                    <RightRespArrow
                      type="button"
                      onClick={onClickHandler}
                      title={label}
                      style={{
                        ...arrowStyles,
                        top: "49%",
                        right: "1%",
                        width: "27px",
                      }}
                    />
                  )
                }
              >
                {meetingRoomImgs?.length > 0 &&
                  meetingRoomImgs?.map((image) => (
                    <div className="singleImage" key={image.key}>
                      <img src={image} />
                    </div>
                  ))}
              </Carousel>
            </div>
            <div className="room-name-description">
              <div className="roomBriefData"></div>
              <p className="room-name">
                {extractFirstValue(roomData?.title, "|")} ({capacity} Seats)
                {/* <span className="mv-mr credits">
                  {roomData?.credits}{" "}
                  <span className="fontWeight">credits</span>
                </span> */}
                <br />
              </p>
              <p className="room-description">{roomData?.description}</p>
            </div>
            <div className="book-room-btn">
              <button onClick={() => handleRoomBooking(roomId, roomName)}>
                Book Now
              </button>
            </div>
            <div className="room-details-info">
              {average ? (
                <div className="rating-of-5">
                  <span className="first">{average.toFixed(1)}</span>
                  <span className="slash">/</span>
                  <span className="total">5</span>
                </div>
              ) : (
                <span className="first" id="noReviewText">
                  no reviews yet.
                </span>
              )}
              <p className="ratings">Overall Based on Ratings</p>
              <p className="room-rating-info">
                publishing and graphic design,
                <br /> department at the university of texas at dalls
              </p>
              {/* <div className="review-rating">
                <div className="room-review-div">
                  <button
                    className="room-review"
                    onClick={() => {
                      if (userReviewed) {
                        toast.error("You can't write a review more than once");
                      } else if (userBookings.length === 0) {
                        toast.error("You dont any meeting bookings to review");
                      } else {
                        handleReviewOpen();
                      }
                    }}
                    close={handleReviewClose}
                  >
                    Write a review
                  </button>
                </div>
                <RatingWidget ratingsArray={ratingFilter} />
              </div> */}
            </div>
            <CustomerWidget
              roomId={roomId}
              roomCustomerData={customerMetaData}
            />
          </div>
          {reviewOpen && (
            <RespReviewDialog
              open={reviewOpen}
              close={handleReviewClose}
              fetchCustomerData={getAllReviewsByRoom}
            />
          )}
        </>
      ) : width <= 1023 ? (
        <div className="meeetingrooms-root tablet">
          <Navbar />
          <div className="nav-home-rightsection">
            <Sidebar
            // homeActive={homeActive}
            // meetingActive={meetingActive}
            // coachesActive={coachesActive}
            />
            <div className="room-details-container">
              <div className="room-details-content">
                <div className="carouselContainer">
                  <Carousel
                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                      hasPrev && (
                        <LeftRespArrow
                          onClick={onClickHandler}
                          title={label}
                          style={{
                            ...arrowStyles,
                            left: "11px",
                            width: "27px",
                          }}
                        />
                      )
                    }
                    renderArrowNext={(
                      onClickHandler,
                      hasNext,
                      hasPrev,
                      label
                    ) =>
                      hasNext && (
                        <RightRespArrow
                          type="button"
                          onClick={onClickHandler}
                          title={label}
                          style={{
                            ...arrowStyles,
                            top: "49%",
                            right: "1%",
                            width: "27px",
                          }}
                        />
                      )
                    }
                  >
                    {meetingRoomImgs?.length > 0 &&
                      meetingRoomImgs?.map((image) => (
                        <div className="singleImage" key={image.key}>
                          <img src={image} />
                        </div>
                      ))}
                  </Carousel>
                </div>
                <p className="room-name">
                  {extractFirstValue(roomData?.title, "|")} ({capacity})
                  {/* <span className="credits">
                    <span className="number"> {roomData?.credits}</span> credits
                  </span> */}
                </p>
                <p className="room-description">{roomData?.description}</p>
                <div className="book-room-btn">
                  <button onClick={() => handleRoomBooking(roomId, roomName)}>
                    Book Now
                  </button>
                </div>
              </div>
              <div className="second-col">
                <div className="room-details-info">
                  {average ? (
                    <div className="rating-of-5">
                      <span className="first">{average.toFixed(1)}</span>
                      <span className="slash">/</span>
                      <span className="total">5</span>
                    </div>
                  ) : (
                    <span className="first" id="noReviewText">
                      no reviews yet.
                    </span>
                  )}
                  <p className="ratings">Overall Based on Ratings</p>
                  <p className="coach-work-info">
                    publishing and graphic design,
                    <br /> department at the university of texas at dalls
                  </p>
                  <button
                    className="room-review"
                    onClick={() => {
                      if (userReviewed) {
                        toast.error("You can't write a review more than once");
                      } else if (userBookings.length === 0) {
                        toast.error("You dont any meeting bookings to review");
                      } else {
                        handleReviewOpen();
                      }
                    }}
                    close={handleReviewClose}
                  >
                    <span>Write a review</span>
                  </button>
                </div>
                <RatingWidget ratingsArray={ratingFilter} />
              </div>
              <CustomerWidget
                roomId={roomId}
                roomCustomerData={customerMetaData}
              />
            </div>
          </div>
          {reviewOpen && (
            <MeetingReviewDialog open={reviewOpen} close={handleReviewClose} />
          )}
        </div>
      ) : (
        // </div>
        <>
          <Navbar />
          <div className="mr-container">
            <Sidebar />
            <div className="room-details-container">
              <div className="room-content">
                <div className="carouselContainer">
                  <Carousel
                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                      hasPrev && (
                        <LeftRespArrow
                          onClick={onClickHandler}
                          title={label}
                          style={{
                            ...arrowStyles,
                            left: "11px",
                            width: "27px",
                          }}
                        />
                      )
                    }
                    renderArrowNext={(
                      onClickHandler,
                      hasNext,
                      hasPrev,
                      label
                    ) =>
                      hasNext && (
                        <RightRespArrow
                          type="button"
                          onClick={onClickHandler}
                          title={label}
                          style={{
                            ...arrowStyles,
                            top: "49%",
                            right: "1%",
                            width: "27px",
                          }}
                        />
                      )
                    }
                  >
                    {meetingRoomImgs?.length > 0 &&
                      meetingRoomImgs?.map((image) => (
                        <div className="singleImage" key={image.key}>
                          <img src={image} />
                        </div>
                      ))}
                  </Carousel>
                </div>
                <p className="room-name">
                  {extractFirstValue(roomData?.title, "|")} ({capacity} Seats) 
                </p>
                <p className="room-description">{roomData?.description}</p>
                <div className="book-room-btn">
                  <button onClick={() => handleRoomBooking(roomId, roomName)}>
                    Book Now
                  </button>
                </div>
              </div>
              <div className="second-col">
                <div className="room-details-info">
                  {average ? (
                    <div className="rating-of-5">
                      <span className="first">{average.toFixed(1)}</span>
                      <span className="slash">/</span>
                      <span className="total">5</span>
                    </div>
                  ) : (
                    <span className="first" id="noReviewText">
                      no reviews yet.{" "}
                    </span>
                  )}
                  <p className="ratings">Overall Based on Ratings</p>
                  {/* <p className="coach-work-info">
                    publishing and graphic design,
                    <br /> department at the university of texas at dalls
                  </p> */}
                  {/* <button
                    className="room-review"
                    onClick={() => {
                      if (userReviewed) {
                        toast.error("You can't write a review more than once");
                      } else if (userBookings.length === 0) {
                        toast.error("You dont any meeting bookings to review");
                      } else {
                        console.log(
                          "userBookings.length",
                          userBookings.length,
                          "userBookings",
                          userBookings
                        );
                        handleReviewOpen();
                      }
                    }}
                    close={handleReviewClose}
                  >
                    <span>Write a review</span>
                  </button> */}
                </div>
                <RatingWidget ratingsArray={ratingFilter} />
              </div>
              <CustomerWidget
                roomId={roomId}
                roomCustomerData={customerMetaData}
              />
            </div>
          </div>
          {reviewOpen && (
            <MeetingReviewDialog
              open={reviewOpen}
              close={handleReviewClose}
              getAllReviewsByRoom={getAllReviewsByRoom}
              fetchCustomerData={getAllReviewsByRoom}
            />
          )}
        </>
      )}
    </div>
  );
};

export default MeetingRoomDetails;
