import "./WalletWidget.css";
import WalletImage from "../../assets/png/WalletImg.png";
import { ReactComponent as BankIcon } from "../../assets/svg/Bank.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { token } from "../../utils/utilityFunctions";
import { useQuery } from "react-query";
import axios from "axios";
import { toast } from "react-toastify";

const WalletWidget = () => {
  let [walletBalance, setWalletBalance] = useState(0);
  const [walletTransactions, setWalletTransactions] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const handleWalletChange = (e) => {
    setWalletBalance(parseInt(e.target.value, 10));
  };

  async function getUserDetails() {
    const userDetails = await axios.get(`${apiUrl}/v1/users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return userDetails;
  }

  const { data, isLoading } = useQuery({
    queryKey: ["userDetails"],
    queryFn: getUserDetails,
  });

  function clearDefaultValue() {
    const input = document.getElementById("myNumberInput");
    if (input && input.value === "0") {
      input.value = "";
    }
  }

  // console.log("data",data.data.body[0].credits)

  const navigate = useNavigate();

  const openPayment = () => {
    console.log(window.location.state);
    if (!walletBalance || walletBalance < 50) {
      toast.warn("Please enter an amount greater than or equal to 50");
      return;
    }
    console.log("walletBalance", walletBalance);
    navigate("/payment", { state: { walletBalance } });
  };

  const incrementby100 = () => {
    setWalletBalance(parseInt(walletBalance, 10) + 100);
  };

  const incrementby500 = () => {
    setWalletBalance(parseInt(walletBalance, 10) + 500);
  };

  const incrementby1000 = () => {
    setWalletBalance(parseInt(walletBalance, 10) + 1000);
  };

  const [width, setWidth] = useState(window.innerWidth);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  const getWallet = async () => {
    try {
      const response = await fetch(`${window.env_url}/v1/wallet`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      const sortedTransactions = data.body[0].sort((a, b) => {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      });

      setWalletTransactions(sortedTransactions);
    } catch (error) {
      console.error(error.message);
    }
  };

  console.log(walletTransactions);

  useEffect(() => {
    getWallet();
    console.log("Use Effect rendered");
  }, []);

  const getDate = (isoString) => {
    const date = new Date(isoString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day.toString().padStart(2, "0")}/${month
      .toString()
      .padStart(2, "0")}/${year}`;
  };

  const getTime = (isoString) => {
    const date = new Date(isoString);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <>
      <div className="wallet-container">
        {width <= 768 ? (
          <>
            <div className="walletImgWrapper">
              <img src={WalletImage} />
              <div className="wallet-label-input">
                <div className="wallet-label">
                  <span className="wallet-credits">
                    Current Credits:{" "}
                    <span>
                      {isLoading ? "Loading..." : data.data.body[0].credits}
                    </span>
                  </span>
                  <BankIcon />
                </div>
                <input
                  type="number"
                  name="number"
                  className="wallet-balance"
                  value={walletBalance}
                  onChange={handleWalletChange}
                  onFocus={clearDefaultValue}
                  id="myNumberInput"
                />
              </div>
            </div>
            <div className="wallet-col">
              <div className="increment-buttons">
                <div className="increment" onClick={incrementby100}>
                  <span>+</span>
                  <span>100</span>
                </div>
                <div className="increment" onClick={incrementby500}>
                  <span>+</span>
                  <span>500</span>
                </div>
                <div className="increment" onClick={incrementby1000}>
                  <span>+</span>
                  <span>1000</span>
                </div>
              </div>
              <div className="add-balance-div">
                <button className="wallet-add-balance" onClick={openPayment}>
                  Add Credits
                </button>
              </div>
              {walletTransactions?.length === 0 ? (
                <p>No Transactions yet.</p>
              ) : (
                <>
                  <h2 className="wallet-hist heading">History</h2>
                  <div className="transaction-history">
                    {walletTransactions?.map((walletData, index) => (
                      <div className="individual-detail" key={index}>
                        <span className="wallet-status wal-action">
                          {walletData?.action}
                        </span>
                        <span className="wallet-status wal-getDate">
                          {getDate(walletData?.createdAt)}
                        </span>
                        <span className="wallet-status wal-getTime">
                          {getTime(walletData?.createdAt)}
                        </span>
                        <span className="amt-added">
                          {walletData?.amount}{" "}
                          <span className="currency">USD</span>
                        </span>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <img src={WalletImage} />
            <div className="wallet-col">
              <div className="wallet-label">
                <span className="wallet-credits">
                  Current Credits:{" "}
                  <span>
                    {isLoading ? "Loading..." : data.data.body[0].credits}
                  </span>
                </span>
                <BankIcon />
              </div>
              <input
                type="number"
                name="number"
                className="wallet-balance"
                value={walletBalance}
                onChange={handleWalletChange}
                onFocus={clearDefaultValue}
                id="myNumberInput"
              />
              <div className="increment-buttons">
                <div className="increment" onClick={incrementby100}>
                  <span>+</span>
                  <span>100</span>
                </div>
                <div className="increment" onClick={incrementby500}>
                  <span>+</span>
                  <span>500</span>
                </div>
                <div className="increment" onClick={incrementby1000}>
                  <span>+</span>
                  <span>1000</span>
                </div>
              </div>
              <button className="wallet-add-balance" onClick={openPayment}>
                Add Credits
              </button>
              {walletTransactions?.length === 0 ? (
                <p>No Transactions yet.</p>
              ) : (
                <>
                  <h2 className="wallet-hist heading">History</h2>
                  <div className="transaction-history">
                    {walletTransactions?.map((walletData, index) => (
                      <div className="individual-detail" key={index}>
                        <span className="wallet-status actionType">
                          {walletData?.action}
                        </span>
                        <span className="wallet-status transDate">
                          {getDate(walletData?.createdAt)}
                        </span>
                        <span className="wallet-status transTime">
                          {getTime(walletData?.createdAt)}
                        </span>
                        <span className="amt-added transAmount">
                          {walletData?.amount}{" "}
                          <span className="currency">USD</span>
                        </span>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default WalletWidget;
