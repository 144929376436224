import "./CoachDetails.css";
import Navbar from "../../../components/navbar/Navbar";
import Sidebar from "../../../components/sidebar/Sidebar";
import CoachPic from "../../../assets/png/Coach Picture.png";
import RespCoachPic from "../../../assets/png/RespCoachPicture.png";
import RatingWidget from "../../../widgets/RatingWidget";
import { ReactComponent as GoLeft } from "../../../assets/svg/GoLeft.svg";
import { ReactComponent as GoRight } from "../../../assets/svg/GoRight.svg";
import { useEffect, useState } from "react";
import CoachReviewDialog from "../../../dialogs/CoachReviewDialog";
import StarRating from "../../../widgets/StarRating";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import MSideBar from "../../../components/sidebar/mobile/MSideBar";
import { ReactComponent as ToggleSideBar } from "../../../assets/svg/ToggleSidebar.svg";
import NotificationWidget from "../../../widgets/NotificationWidget";
import {
  customerMetaData,
  getFileUrl,
  token,
} from "../../../utils/utilityFunctions";
import RespReviewDialog from "../../../dialogs/profile-ui/RespReviewDialog";
import MNavbar from "../../../components/navbar/mobile/MNavbar";
import { useNavigate, useParams } from "react-router-dom";
import CustomerCard from "../../../cards/CustomerCard";
import CustomerWidget from "../../../widgets/CustomerWidget/CustomerWidget";
import { useQuery } from "react-query";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
const apiURL = process.env.REACT_APP_API_URL;

const CoachDetails = () => {
  const [reviewOpen, setReviewOpen] = useState(false);
  const handleReviewOpen = () => setReviewOpen(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [allReviews, setAllReviews] = useState([]);
  const [userReviewed, setUserReviewed] = useState(false);
  const handleReviewClose = () => setReviewOpen(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [currentCustomer, setCurrentCustomer] = useState(1);
  const [skills, setSkills] = useState([]);
  const [userBookings, setUserBookings] = useState();
  const { coachId } = useParams();
  // const {isLoading, data} = useQuery({queryKey:"userReview",
  //   queryFn:async function (){
  //     try{
  //       const response = await axios.get(`${apiURL}/v1/coachreviews/${coachId}`, {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         },
  //       })
  //     }catch(err){
  //       console.log("error fetching coach Reviews")
  //     }
  //     },
  //   onSuccess:(data)=>{
  //     setUserReviewed(true)
  //     console.log("here",data)
  //   }
  //   })

  const navigate = useNavigate();

  const [coachData, setCoachData] = useState({
    title: "Lorem Ipsum",
    credits: 420,
    description:
      "publishing and graphic design, Lorem ipsum is a placeho tcommonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholderbes available.publishing and graphic design, Lorem ipsum is a placeholder te",
    files: "../../../assets/png/Coach Picture.png",
  });

  // console.log("coachData.skills", coachData);
  const [customerMetaData, setCustomerMetaData] = useState(null);

  const fetchCoachData = async () => {
    const apiUrl = `${window.env_url}/v1/coaches/${coachId}`;
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP Status error! ${response.status}`);
      }
      const data = await response.json();
      setSkills(String(data.body[0].skills).split("|") || []);
      setCoachData(data.body[0]);
    } catch (error) {
      console.error(error);
    }
  };

  const coachName = coachData?.title;

  const fetchAllReviews = async () => {
    const userId = jwtDecode(token).id;
    try {
      const response = await fetch(
        `${window.env_url}/v1/coachreviews/coach/${coachId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setAllReviews(data.body[0]);
      setCustomerMetaData(data.body[0]);
      setUserReviewed(data.body[0].some((review) => review.userId === userId));
      console.log(
        "true or false",
        data.body[0].some((review) => review.userId === userId)
      );
    } catch (error) {
      console.error(error.message);
    }
  };

  const fetchCoachBookingByUser = async () => {
    const apiUrl = `${window.env_url}/v1/coachbookings/${coachId}?user=true`;
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP Status error! ${response.status}`);
      }
      const data = await response.json();
      setUserBookings(data?.body[0] || []);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchCoachData();
    fetchAllReviews();
    fetchCoachBookingByUser();
  }, [coachId]);

  const ratings = allReviews?.map((review) => {
    return review.rating;
  });
  const sum = ratings?.reduce((acc, rating) => acc + rating, 0);
  const average = sum / ratings?.length;
  //console.log(ratings);
  //console.log("z", average);

  const ratingFilter = [0, 0, 0, 0, 0];
  allReviews?.forEach((review) => {
    if (review.rating >= 1 && review.rating <= 5) {
      ratingFilter[review.rating - 1]++;
    }
    return review.rating;
  });

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const leftClickHandler = () => {
    if (currentCustomer !== 1) {
      setCurrentCustomer((prev) => prev - 1);
    }
  };
  const rightClickHandler = () => {
    if (currentCustomer !== 4) {
      setCurrentCustomer((prev) => prev + 1);
    }
  };

  const MCustomerCard = ({ id, imgurl, rating, name, description }) => {
    return (
      <div className="coach-card custR-mv" key={id}>
        <img src={imgurl} className="coach-img custR" />
        <div className="customer-rating">
          <StarRating rating={rating} totalStars={5} fontSize="5vw" />
        </div>
        <div className="cust-name-content">
          <div className="customer-details">
            <span className="cust-name">
              {name}
              {id}
            </span>
            <span>{description}</span>
          </div>
        </div>
      </div>
    );
  };

  const handleCoachBooking = (coachId, coachName) => {
    //console.log("Room", coachId);
    navigate(`/coach-booking-schedule/${coachId}`, { state: { coachName } });
  };

  const getFileUrl = `${window.env_url}/v1/files/`;

  return (
    <div className="coachDetailsContainer mr-root">
      {width <= 768 ? (
        <>
          <MNavbar />
          <div className="cd-container">
            <div className="top-section">
              {/* <div
                className="coach-picture"
                style={{
                  backgroundImage: `url(${getFileUrl + coachData.files})`,
                }}
              ></div> */}
              <div className="coach-image-container">
                {" "}
                <img
                  src={`${getFileUrl + coachData.files}`}
                  alt="coach-image"
                />
              </div>
              <div className="subtitle">
                <span className="coach-name-desg">
                  <span className="title-and-credits">
                    <span className="name">{coachData.title}</span>
                    {/* <span className="coach-credits">
                      ({coachData.credits} Credits)
                    </span> */}
                  </span>
                  <span className="desg">({coachData.skills})</span>
                </span>
              </div>
              <p className="description">{coachData.description}</p>
            </div>
            <div className="book-room-btn">
              <button
                className="book-coach-btn"
                onClick={() => handleCoachBooking(coachId)}
              >
                Book Now
              </button>
            </div>
            <div className="room-details-info">
              {average ? (
                <div className="rating-of-5">
                  <span className="first">{average.toFixed(1)}</span>
                  <span className="slash">/</span>
                  <span className="total">5</span>
                </div>
              ) : (
                <span className="first no-average">
                  has got no reviews yet.{" "}
                </span>
              )}
              <p className="ratings">Overall Based on Ratings</p>
              <p className="room-rating-info">
                publishing and graphic design,
                <br /> department at the university of texas at dalls
              </p>
              <div className="review-rating">
                <div className="room-review-div">
                  <button
                    className="room-review"
                    onClick={() => {
                      if (userReviewed) {
                        toast.warn("You can't write a review more than once");
                      } else if (userBookings.length === 0) {
                        toast.error("You dont any meeting bookings to review");
                      } else {
                        handleReviewOpen();
                      }
                    }}
                    close={handleReviewClose}
                  >
                    Write a review
                  </button>
                </div>
                <RatingWidget ratingsArray={ratingFilter} />
              </div>
            </div>

            <CustomerWidget
              coachId={coachId}
              coachCustomerData={customerMetaData}
            />
          </div>
          {reviewOpen && (
            <RespReviewDialog open={reviewOpen} close={handleReviewClose} />
          )}
        </>
      ) : width <= 1024 ? (
        <div className="meeetingrooms-root tablet">
          <Navbar />
          <div className="nav-home-rightsection">
            <Sidebar
            // homeActive={homeActive}
            // meetingActive={meetingActive}
            // coachesActive={coachesActive}
            />
            <div className="cd-container">
              <div className="top-section">
                <div
                  className="coach-picture-container"
                  // style={{ backgroundImage: `url(${getFileUrl + coachData.files})` }}
                >
                  <img
                    className="coach-picture"
                    src={getFileUrl + coachData.files}
                  />
                </div>
                <div className="subtitle">
                  <p className="coach-designation">
                    <span className="coach-name">{coachData.title}</span>{" "}
                    {/* <span>({coachData.skills})</span> */}
                  </p>
                  <div className="skill-container">
                    {skills?.map((skill, index) => {
                      return <div key={index}>{skill}</div>;
                    })}
                  </div>
                </div>
                <p className="description">{coachData.description}</p>
              </div>
              <div className="book-room-btn coach-details">
                <button
                  className="book-coach-btn"
                  onClick={() => handleCoachBooking(coachId)}
                >
                  Book Now
                </button>
              </div>

              <div className="second-col">
                <div className="room-details-info">
                  {average ? (
                    <div className="rating-of-5">
                      <span className="first">{average.toFixed(1)}</span>
                      <span className="slash">/</span>
                      <span className="total">5</span>
                    </div>
                  ) : (
                    <span className="noReviewText">
                      has got no reviews yet.{" "}
                    </span>
                  )}
                  <p className="ratings">Overall Based on Ratings</p>
                  <p className="coach-work-info">
                    publishing and graphic design,
                    <br /> department at the university of texas at dalls
                  </p>
                  <button
                    className="room-review"
                    onClick={() => {
                      if (userReviewed) {
                        toast.warn("You can't write a review more than once");
                      } else if (userBookings.length === 0) {
                        toast.error("You dont any meeting bookings to review");
                      } else {
                        handleReviewOpen();
                      }
                    }}
                    close={handleReviewClose}
                  >
                    <span>Write a review</span>
                  </button>
                </div>
                <RatingWidget ratingsArray={ratingFilter} />
              </div>
              <CustomerWidget
                coachId={coachId}
                coachCustomerData={customerMetaData}
              />
            </div>
          </div>
          {reviewOpen && (
            <CoachReviewDialog open={reviewOpen} close={handleReviewClose} />
          )}
        </div>
      ) : (
        <>
          <Navbar />
          <div className="mr-container">
            <Sidebar />

            <div className="coachDetails coach-container">
              <div className="coach-content">
                <div className="coach-content-right">
                  <p className="coach-designation">
                    <span className="coach-name">{coachData.title}</span>{" "}
                  </p>
                  <div className="skill-container">
                    {skills?.map((skill, index) => {
                      return <div key={index}>{skill}</div>;
                    })}
                  </div>
                  <p className="coach-description">{coachData.description}</p>
                </div>
                {/* <span>{coachData.skills}</span> */}
                <div className="coach-img-btn">
                  <img
                    src={getFileUrl + coachData.files}
                    alt="Picture of a coach"
                  />
                  <button
                    style={{ border: "none" }}
                    onClick={() => handleCoachBooking(coachId, coachName)}
                  >
                    Book Now
                  </button>
                </div>
              </div>
              <div className="second-col" id="coachDetailsRatingsSec">
                <RatingWidget ratingsArray={ratingFilter} />

                <div className="coach-personal-info">
                  <div className="coach-name coach-details">
                    {coachData.title}
                  </div>
                  {average ? (
                    <div className="rating-of-5">
                      <span className="first">{average.toFixed(1)}</span>
                      <span className="slash">/</span>
                      <span className="total">5</span>
                    </div>
                  ) : (
                    <span className="first">has got no reviews yet. </span>
                  )}
                  <p className="ratings">Overall Based on Ratings</p>
                  <p className="coach-work-info">
                    professor in the computer science
                    <br /> department at the university of texas at dalls
                  </p>
                  {/* <button
                    className="coach-review"
                    onClick={() => {
                      if (userReviewed) {
                        toast.warn("You can't write a review more than once");
                      } else if (userBookings.length === 0) {
                        toast.error("You dont any meeting bookings to review");
                      } else {
                        handleReviewOpen();
                      }
                    }}
                  >
                    <span>Write a review</span>
                  </button> */}
                </div>
              </div>
              <CustomerWidget
                coachId={coachId}
                coachCustomerData={customerMetaData}
              />
            </div>
          </div>
          {reviewOpen && (
            <CoachReviewDialog
              open={reviewOpen}
              close={handleReviewClose}
              fetchAllReviews={fetchAllReviews}
            />
          )}
        </>
      )}
    </div>
  );
};

export default CoachDetails;
