import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import BasicDetailsSection from "../components/register-login-quickbook/quickBookingSections/basicDetails";
import MeetingRoomDetailsSection from "../components/register-login-quickbook/quickBookingSections/meetingRoomDetails";
import SetupDetailsSection from "../components/register-login-quickbook/quickBookingSections/setupDetails";
import CircularProgress from "@mui/material/CircularProgress";
import InputField from "../components/register-login-quickbook/inputField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import QbStatus1 from "../assets/png/QbStatus1.png";
import QbStatus2 from "../assets/png/QbStatus2.png";
import QbStatus3 from "../assets/png/QbStatus3.png";
import { ReactComponent as QB1 } from "../assets/svg/status1.svg";
import { ReactComponent as QB2 } from "../assets/svg/status2.svg";
import { ReactComponent as QB3 } from "../assets/svg/status3.svg";
import NFIHLogo from "../assets/png/NFIH-logo.png"
import HomePic from "../assets/png/home img.png";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay-ts";
import { ReactComponent as AppLogo } from "../assets/svg/IncuHub.svg";
import "./QuickBooking.css";
import { useState } from "react";
import { toast } from "react-toastify";
import AnimatedText from "../utils/AnimatedText";
import { ReactComponent as QBB1 } from "../assets/svg/QBB1.svg";
import { ReactComponent as QBB2 } from "../assets/svg/QBB2.svg";
import { ReactComponent as QBB3 } from "../assets/svg/QBB3.svg";
import { ReactComponent as First } from "../assets/svg/first.svg";
import { ReactComponent as Middle } from "../assets/svg/middle.svg";
import { ReactComponent as Last } from "../assets/svg/last.svg";

const screenWidth = window.innerWidth;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  paddingRight: screenWidth > 900 ? "10px" : "0px",
  minWidth: screenWidth > 900 ? "85%" : "90%",
  height: screenWidth > 900 ? "88%" : "max-content",
  borderRadius: "20px",
  overflowY: "hidden",
  overflowX: "hidden",
};

const tempSetUpDetailsStyle = {
  gap: "1em",
};

export default function QuickBooking({ open, handleClose }) {
  const apiURL = process.env.REACT_APP_API_URL;

  const [formValues, setFormState] = useState({
    basicDetails: {
      firstname: "",
      lastname: "",
      email: "",
      contact: "",
    },
    meetingRoomDetails: {
      roomTitle: "", // Initial value for dropdown
      guests: "",
      date: "",
      time: "",
      details: "",
    },
    setupDetails: {
      seats: "",
      facilities: "",
      notes: "",
      duration: "15 minutes",
    },
  });

  console.log(formValues);

  const [formSection, setFormSections] = useState(1);
  const [loading, setLoading] = useState(false);
  const [bookingLoader, setBookingLoader] = useState(false);

  const validateEmail = async () => {
    try {
      console.log("formValues.basicDetails", formValues.basicDetails);
      const formData = new FormData();
      formData.append("email", formValues.basicDetails.email);
      formData.append("firstname", formValues.basicDetails.firstname);
      formData.append("lastname", formValues.basicDetails.lastname);
      formData.append("contact", formValues.basicDetails.contact);
      formData.append("address", "placeholder");
      formData.append("about", "placeholder");

      const response = await axios.post(
        `${apiURL}/v1/users/quickbook`,
        formData,
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );

      return response.data;
    } catch (error) {
      console.log("There was an error validating the email", error);
    }
  };

  const handleOnClickNext = async (e) => {
    e.preventDefault();
    setLoading(true);
    const basicDetails = formValues.basicDetails;

    if (
      formSection === 1 &&
      (!basicDetails.contact.trim() ||
        !basicDetails.email.trim() ||
        !basicDetails.firstname.trim() ||
        !basicDetails.lastname.trim())
    ) {
      toast.error("Please fill all the fields");
      setLoading(false);
      return;
    }

    if (formSection + 1 === 2) {
      const data = await validateEmail(formValues.email);
      console.log(data);

      if (data?.status === 200) {
        setLoading(false);
        const token = data.body[1]["token"];
        localStorage.setItem("quickBookToken", token);
      } else {
        setLoading(false);
        toast.error("Please Enter a registered email");
        return;
      }
    }

    setFormSections((prevState) => prevState + 1);
    setLoading(false);
  };

  const handleOnClickBack = async (e) => {
    e.preventDefault();
    setFormSections((prevState) => prevState - 1);
  };

  const handleOnClickBook = async (e) => {
    e.preventDefault();
    setBookingLoader(true);
    const temp = formValues.setupDetails;

    if (
      !temp.date ||
      !temp.notes ||
      !temp.facilities ||
      !temp.duration ||
      !temp.time
    ) {
      toast.warn("Please fill all the fields");
      return;
    }
    window.scrollTo(0, 0);
    console.log(!temp.facilities);

    const setupDetails = formValues.setupDetails;
    const requestFormData = new FormData();
    console.log("checkInDate", setupDetails.date);
    console.log("checkInTime", setupDetails.time);
    console.log("checkOutDate", setupDetails.date);
    console.log("checkOutTime", setupDetails.checkoutTime);
    console.log("facilities", setupDetails.facilities);
    console.log("duration", setupDetails.duration[0]);
    console.log("notes", formValues.roomData.id);

    requestFormData.append("checkInDate", setupDetails.date);
    requestFormData.append("checkInTime", setupDetails.time);
    requestFormData.append("checkOutDate", setupDetails.date);
    requestFormData.append("checkOutTime", setupDetails.checkoutTime);
    requestFormData.append("facilities", setupDetails.facilities);
    requestFormData.append("duration", setupDetails.duration[0]);
    requestFormData.append("notes", setupDetails.notes);

    try {
      const token = localStorage.getItem("quickBookToken");
      await axios
        .post(
          `${apiURL}/v1/meetingbookings/book/${formValues.roomData.id}`,
          requestFormData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          setBookingLoader(false);
          toast.success("booking created");
          console.log(response);
          window.location.reload();
        });
    } catch (error) {
      setBookingLoader(false);
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
      console.log(error);
      return;
    }
  };

  let conditionalClassForBtn;
  if (formSection === 3) {
    conditionalClassForBtn = "qb3btn";
  } else if (formSection === 2) {
    conditionalClassForBtn = "qb2-btn";
  }

  const logo = localStorage.getItem("adminImage");
  const getFileUrl = `${window.env_url}/v1/files/`;

  const getStepIcon = () => {
    if (formSection === 1) {
      return <First />;
    } else if (formSection === 2) {
      return <Middle />;
    } else {
      return <Last />;
    }
  };
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="registerModal quickbooking-modal"
        onClose={() => {
          handleClose(false);
        }}
        BackdropProps={{
          sx: {
            backgroundColor: "#87bad3", // Custom backdrop color
            transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms", // Custom transition
          },
        }}
      >
        <>
          <LoadingOverlay spinner active={bookingLoader}></LoadingOverlay>
          <Box component="form" sx={{ ...style }} className="user-qb-container">
            <div className="quick-book-dialog">
              <div className="leftSection QbleftSection">
                <div className="QbLeftSectionInnerContainer">
                  <AnimatedText
                    text="Welcome to IncuHub!"
                    delay={100}
                    className="qb-heading"
                  />
                  <span className="home-desc">
                    A comprehensive incubation management system designed to
                    streamline and enhance the processes of incubation centers.
                  </span>
                  <div className="img-container">
                    <img src={HomePic} className="home-image" />
                  </div>
                </div>
              </div>

              <div
                className={`rightSection QbRightSection ${
                  screenWidth > 900 && formSection === 3 ? "tempSetupStyle" : ""
                }`}
              >
                {/* <div className="rightContentsContainer"> */}
                <div className="logo-data">
                  <div className="logo">
                    {/* <img src={getFileUrl + logo} className="login-logo" /> */}
                    <img src={NFIHLogo} className="login-logo" />
                  </div>
                  <div className="data">
                    <h1>Quick Booking</h1>
                    <span>Follow the below steps and get quick response</span>
                  </div>
                  <div className="statusImage">{getStepIcon()}</div>
                  <div className="statusDescription">
                    <span
                      className={
                        formSection === 1 ? "statusActive" : "statusInactive"
                      }
                    >
                      Basic Details
                    </span>
                    <span
                      className={
                        formSection === 2 ? "statusActive" : "statusInactive"
                      }
                    >
                      Meeting Room Details
                    </span>
                    <span
                      className={
                        formSection === 3 ? "statusActive" : "statusInactive"
                      }
                    >
                      Set Up details
                    </span>
                  </div>
                </div>
                {!loading && (
                  <>
                    {formSection === 1 && (
                      <BasicDetailsSection
                        formState={formValues}
                        setFormState={setFormState}
                      />
                    )}
                    {formSection === 2 && (
                      <MeetingRoomDetailsSection
                        formState={formValues}
                        setFormState={setFormState}
                      />
                    )}
                    {formSection === 3 && (
                      <SetupDetailsSection
                        formState={formValues}
                        setFormState={setFormState}
                      />
                    )}
                  </>
                )}
                <div className={`QbButtonsWrapper ${conditionalClassForBtn}`}>
                  {formSection === 1 && (
                    <div>
                      <button
                        onClick={handleOnClickNext}
                        className="qb3-action-btn next"
                      >
                        Next
                      </button>
                    </div>
                  )}
                  {formSection === 2 && (
                    <div className="qb2btn">
                      <button
                        className="qb3-action-btn"
                        onClick={handleOnClickBack}
                      >
                        Back
                      </button>
                      <button
                        className="qb3-action-btn next"
                        onClick={handleOnClickNext}
                      >
                        Next
                      </button>
                    </div>
                  )}
                  {formSection === 3 && (
                    <div>
                      <button
                        className="qb3-action-btn"
                        onClick={handleOnClickBack}
                      >
                        Back
                      </button>
                      <button
                        className="qb3-action-btn next"
                        onClick={handleOnClickBook}
                      >
                        Book
                      </button>
                    </div>
                  )}
                </div>
                {/* </div> */}
              </div>
            </div>
          </Box>
        </>
      </Modal>
    </div>
  );
}
