import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "../../../widgets/CalendarWidget/CalendarWidget.css";
import BookForm from "../../form/BookForm";
import MultiSelectDropdown from "../../../components/MultiSelect";
import { useParams } from "react-router-dom";
import { hours, token } from "../../../utils/utilityFunctions";
import { db } from "../../../widgets/firebase";
import { addDoc, collection } from "firebase/firestore";
import { Tooltip, colors, styled, tooltipClasses } from "@mui/material";
import { toast } from "react-toastify";
import Asterisk from "../../../utils/Asterisk";
import SingleSelectDropdown from "../../../components/SingleSelect";
import LoaderSpinner from "../../../utils/shimmer/LoaderSpinner";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const CoachCalendarWidget = ({
  selectedMonth,
  setSelectedMonth,
  calendarView,
  setCalendarView,
  coachName,
  setSelectedBooking,
  getAllEvents,
  eventss
}) => {
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [skillsData, setSkillsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const ref = collection(db, "userrequest");

  const { id } = useParams();

  const updateSkills = (e) => {
    // setSelectedSkills(e.value);
    const {
      target: { value },
    } = e;
    setSelectedSkills(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };


  const handleViewChange = (view) => {
    if (view === Views.MONTH || view === "month") {
      const startDate = moment().startOf("month").format("YYYY-MM-DD");
      const endDate = moment()
        .startOf("month")
        .add(1, "month")
        .format("YYYY-MM-DD");
      setSelectedMonth({ startDate, endDate });
      setCalendarView("Monthly");
    } else if (view === Views.WEEK || view === "week") {
      const startDate = moment().startOf("week").format("YYYY-MM-DD"); // Start of the current week (Sunday)
      const endDate = moment().endOf("week").format("YYYY-MM-DD"); // End of the current week (Saturday)
      setSelectedMonth({ startDate, endDate });
      setCalendarView("Weekly");
    } else if (view === Views.DAY || view === "day") {
      const startDate = moment().startOf("day").format("YYYY-MM-DD"); // Start of today
      const endDate = moment().startOf("day").format("YYYY-MM-DD"); // Start of today (same as start)
      setSelectedMonth({ startDate, endDate });
      setCalendarView("Day");
    }
  };

  const handleNavigate = (date, view) => {
    if (view === Views.MONTH || view === "month") {
      const startDate = moment(date).startOf("month").format("YYYY-MM-DD");
      const endDate = moment(date)
        .startOf("month")
        .add(1, "month")
        .format("YYYY-MM-DD");
      setSelectedMonth({ startDate, endDate });
    } else if (view === Views.WEEK || view === "week") {
      const startDate = moment(date).startOf("week").format("YYYY-MM-DD"); // Start of the week (Sunday)
      const endDate = moment(date).endOf("week").format("YYYY-MM-DD"); // End of the week (Saturday)
      setSelectedMonth({ startDate, endDate });
    } else if (view === Views.DAY || view === "day") {
      const startDate = moment(date).startOf("day").format("YYYY-MM-DD"); // Start of the provided date
      const endDate = moment(date).startOf("day").format("YYYY-MM-DD"); // Start of the provided date (same as start)
      setSelectedMonth({ startDate, endDate });
    }
  };

  console.log(selectedMonth);

  const getFileUrl = `${window.env_url}/v1/files/`;

  const profileImage = sessionStorage.getItem("profPicture");
  let imgPath;
  if (profileImage?.includes("avataaars.io")) {
    imgPath = profileImage;
  } else {
    imgPath = getFileUrl + profileImage;
  }

  console.log(selectedMonth);

  useEffect(() => {
    const startDate = moment().startOf("month").format("YYYY-MM-DD");
    const endDate = moment().endOf("month").format("YYYY-MM-DD");
    setSelectedMonth({ startDate, endDate });
  }, []);


  const currentUserId = sessionStorage.getItem("userId");
  const numUserId = Number(currentUserId);

  const formattedEvents = eventss
    ?.filter(
      (event) =>
        event?.visibility === "accepted" ||
        event?.visibility === "pending" ||
        (event?.visibility === "rejected" && event?.userId === numUserId)
    )
    .map((event) => {
      const startDateTime = moment(
        `${event.checkInDate} ${event.checkInTime}`,
        "YYYY-MM-DD HH:mm:ss"
      );
      const endDateTime = moment(
        `${event.checkOutDate} ${event.checkOutTime}`,
        "YYYY-MM-DD HH:mm:ss"
      );
      return {
        id: event.id,
        title: "", // You can customize this if you have specific titles
        start: startDateTime.toDate(), // Format the start datetime
        end: endDateTime.toDate(), // Format the end datetime
        skills: event.skills, // Assuming event.facilities is an array of facilities
        notes: event.notes,
        visibility: event?.visibility,
        isRescheduled: event?.isRescheduled,
        userId: event?.userId,
      };
    });

  console.log("formattedEvents", formattedEvents);

  useEffect(() => {
    fetchSkills();
    getAllEvents();
  }, []);

  const fetchSkills = async () => {
    const apiUrl = `${window.env_url}/v1/filterskills`;
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setSkillsData(data.body[0] || []);
    } catch (error) {
      console.error(error.message);
    }
  };

  const skills = skillsData.map((data, index) => {
    return {
      name: data.title,
      code: data.id,
    };
  });

  const [events, setEvents] = useState([
    {
      title: "Event 1",
      start: new Date(),
      allDay: true,
      end: new Date(2024, 2, 1),
    },
  ]);

  const [width, setWidth] = useState(window.innerWidth);

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  const onEventResize = (data) => {
    const { start, end } = data;
    setEvents([{ ...events[0], start, end }]);
  };
  const onEventDrop = (data) => {
    //console.log(data);
  };

  const today = new Date().toISOString().split('T')[0];

  const [newEvent, setNewEvent] = useState({
    title: "",
    start: "",
    end: "",
    startTime: "",
    endTime: "",
    skills: "",
    duration: "",
    notes: "",
  });

  const [allEvents, setAllEvents] = useState(events);

  const handleAddEvent = () => {
    const startTime = moment(newEvent.startTime, "HH:mm:ss");
    const startTimeMoment = moment(startTime, "HH:mm");
    const endTimeMoment = startTimeMoment
      .clone()
      .add(newEvent.duration, "hours");

    let startDate = moment(newEvent.start);
    let endDate = moment(newEvent.end);
    let checkOutDateTime = moment(
      `${newEvent.start} ${newEvent.startTime}`,
      "YYYY-MM-DD HH:mm:ss"
    ).add(newEvent.duration, "hours"); // Calculate checkout date/time

    const coachEvent = {
      checkInDate: moment(startDate).format("YYYY-MM-DD"),
      checkOutDate: checkOutDateTime.format("YYYY-MM-DD"),
      checkInTime: startTimeMoment.format("HH:mm:ss"),
      checkOutTime: endTimeMoment.format("HH:mm:ss"),
      skills: selectedSkills.map((item, i) => item),
      duration: newEvent.duration,
      notes: newEvent.notes,
    };

    const event = coachEvent;
    setAllEvents([...allEvents, event]);
    const postCoachBooking = async () => {
      const token = localStorage.getItem("accessToken");
      try {
        setIsLoading(true);
        const response = await fetch(
          `${window.env_url}/v1/coachbookings/book/${id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(event),
          }
        );
        const data = await response.json();

        if (!response.ok) {
          console.log("data", data);
          if (data.status === 500) {
            toast.error("Invalid Details provided");
          } else {
            toast.error(data.message);
          }
        } else {
          toast.success("Coach booking request made");
          getAllEvents();
          const data = await response.json();
          let dataToSend = {
            username: sessionStorage.getItem("fullName"),
            message: "Booked a Coach",
            duration: data.body[0].duration,
            profileImage: imgPath,
            createdAt: Date.now(),
          };
          // getAllEvents();
          console.log(dataToSend);
          try {
            await addDoc(ref, dataToSend);
            console.log("Data sent to Firebase successfully", dataToSend);
          } catch (firebaseError) {
            console.log("Error adding data to Firebase:", firebaseError);
          }
          
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        setIsLoading(false);
        setNewEvent({
          title: "",
          start: "",
          end: "",
          startTime: "",
          endTime: "",
          skills: "",
          duration: "",
          notes: "",
        });
        setSelectedSkills([]);
      }
    };

    postCoachBooking();
  };

  const EventComponent = ({ event }) => {
    let visibilityColor;
    let textColor;

    switch (event?.visibility) {
      case "accepted":
        visibilityColor = "#265985";
        textColor = "white";
        break;
      case "pending":
        visibilityColor = "yellow";
        textColor = "black";
        break;
      case "rejected":
        visibilityColor = "red";
        textColor = "white";
        break;
      default:
        visibilityColor = "black"; // Default color if visibility is not specified or unknown
        break;
    }

    const eventStyle = {
      backgroundColor: visibilityColor,
      color: textColor,
    };

    //tool tip from material ui

    const CustomWidthTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))({
      [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 500,
      },
    });

    const skills = event?.skills
      ? event?.skills.join(", ")
      : "No skills available";
    const notes = event?.notes;

    const tooltipContent = `
   Skills : ${skills}, 
   Notes : ${notes}
    `;

    return (
      <div style={eventStyle}>
        <Tooltip title={tooltipContent}>
          <div>{coachName}</div>
          <div style={{ textTransform: "capitalize" }}>
            Status : {event?.visibility}
          </div>
        </Tooltip>
      </div>
    );
  };
  const calculateDuration = () => {
    if (newEvent.startTime && newEvent.endTime) {
      const start = moment(`2024-01-01T${newEvent.startTime}`);
      const end = moment(`2024-01-01T${newEvent.endTime}`);
      const durationMinutes = end.diff(start, "minutes");
      const durationUnits = durationMinutes / 15; // 15 minutes is 1 unit
      setNewEvent({ ...newEvent, duration: durationUnits });
    }
  };

  return (
    <>
      <>
        <div className="tl-container">
          <div className="cal-wrapper" style={{ height: "auto" }}>
            <Calendar
              defaultDate={moment().toDate()}
              defaultView="month"
              events={formattedEvents}
              localizer={localizer}
              startAccessor="start"
              endAccessor="end"
              onEventDrop={onEventDrop}
              onEventResize={onEventResize}
              onView={(view) => handleViewChange(view)}
              onNavigate={(date, view) => handleNavigate(date, view)}
              resizable
              onSelectEvent={(event) => setSelectedBooking(event)}
              showMultiDayTimes
              views={["month", "week", "day"]}
              style={{ height: "100vh" }}
              components={{
                event: EventComponent,
                agenda: { event: EventComponent },
                week: { event: EventComponent },
                day: { event: EventComponent },
              }}
            />
          </div>
        </div>

        {width >= 1024 && (
          <div className="bmrf" style={{ fontFamily: "Nunito" }}>
            {isLoading && (
              <div className="spinner-overlay">
                <LoaderSpinner />
              </div>
            )}
            <div className="bmrf-heading">
              <p>MAKE A COACH BOOKING</p>
              <span
                style={{
                  fontFamily: "Nunito",
                  fontStyle: "italic",
                  display: "block",
                  width: "100%",
                  textAlign: "center",
                  paddingTop: "1%",
                }}
              >
                Fields with asterisk (*) are required
              </span>
            </div>
            <div className="bmrf-form">
              <div className="row1">
                <div>
                  <label className="makebold" htmlFor="">
                    Date
                    <Asterisk />
                  </label>
                  <input
                    type="date"
                    name="startDate"
                    value={newEvent.start}
                    onChange={(event) =>
                      setNewEvent({ ...newEvent, start: event.target.value })
                    }
                    min={today}
                  />
                </div>
              </div>
              <div className="row2">
                <div>
                  <label className="makebold" htmlFor="">
                    Time
                    <Asterisk />
                  </label>
                  <input
                    type="time"
                    name="startTime"
                    value={newEvent.startTime}
                    onChange={(event) => {
                      setNewEvent({
                        ...newEvent,
                        startTime: event.target.value,
                      });
                      calculateDuration();
                    }}
                  />
                </div>
              </div>
              <div className="row3" style={{ border: "none" }}>
                <div>
                  <label className="makebold" htmlFor="">
                    Duration
                    <Asterisk />
                  </label>
                  {/* <input
                    type="number"
                    name="duration"
                    value={newEvent.duration}
                    placeholder="Duration"
                    onChange={(event) =>
                      setNewEvent({ ...newEvent, duration: event.target.value })
                    }
                  /> */}
                  <SingleSelectDropdown
                    options={hours}
                    value={newEvent.duration}
                    onChange={(event) =>
                      setNewEvent({ ...newEvent, duration: event.target.value })
                    }
                    label="Duration"
                    isMandatory={true}
                  />
                </div>
              </div>
              <div className="row4">
                <MultiSelectDropdown
                  value={selectedSkills}
                  onChangeEvent={updateSkills}
                  options={skills}
                  isMandatory={true}
                  placeholder="Select Skills"
                />
              </div>
              <div className="row5">
                <div className="row5" style={{ display: "block" }}>
                  <label className="makebold" htmlFor="">
                    Notes
                    <Asterisk />
                  </label>
                  <textarea
                    type="text"
                    name="notes"
                    style={{ marginTop: "6px" }}
                    value={newEvent.notes}
                    placeholder="Description"
                    onChange={(event) =>
                      setNewEvent({ ...newEvent, notes: event.target.value })
                    }
                  />
                </div>
              </div>
              <div className="form-sbt-btn" onClick={handleAddEvent}>
                <button>Submit</button>
              </div>
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default CoachCalendarWidget;

//events prop must be supplied an object containing start and end params so that it can be mapped to the particular
//day, time and then the title for whatever you want to display there in the calendar

//CONTROLLING THE CALENDAR
// calendar can be controlled by passing necessary props to it (eg. views,defaultView,max, min )
