import React from "react";
import "./CredentialsForm.css";
import EyeIcon from "../../assets/png/EyeIcon.png";
import hideEyeIcon from "../../assets/png/HideEyeIcon.png";
import { useState } from "react";

function CredentialsForm({ onClose }) {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [conNewPassword, setNewConPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConNewPassword, setShowConNewPassword] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };
  const handleConNewPasswordChange = (e) => {
    setNewConPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConNewPasswordVisibility = () => {
    setShowConNewPassword(!showConNewPassword);
  };

  return (
    <>
      <div className="formCredWrapper">
        <form action="">
          <div className="formCredInputWrapper">
            <label htmlFor="email">Email</label>
            <input type="email" name="email" id="email" />
          </div>

          <div className="formCredInputWrapper">
            <label htmlFor="cPassword">Current Password</label>
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              name="cPassword"
              id="cPassword"
              onChange={handlePasswordChange}
            />
            <div
              className={`iconDiv ${showPassword ? "visible" : ""}`}
              onClick={togglePasswordVisibility}
            >
              <img src={showPassword ? EyeIcon : hideEyeIcon} alt="" />
            </div>
          </div>
          {/* new Password */}
          <div className="formCredInputWrapper">
            <label htmlFor="nPassword">New Password</label>
            <input
              type={showNewPassword ? "text" : "password"}
              value={newPassword}
              name="nPassword"
              id="nPassword"
              onChange={handleNewPasswordChange}
            />
            <div
              className={`iconDiv ${showPassword ? "visible" : ""}`}
              onClick={toggleNewPasswordVisibility}
            >
              <img src={showNewPassword ? EyeIcon : hideEyeIcon} alt="" />
            </div>
          </div>

          <div className="formCredInputWrapper">
            <label htmlFor="conPassword">Confirm New Password</label>
            <input
              type={showConNewPassword ? "text" : "password"}
              name="conPassword"
              id="conPassword"
              value={conNewPassword}
              onChange={handleConNewPasswordChange}
            />
            <div
              className={`iconDiv ${showPassword ? "visible" : ""}`}
              onClick={toggleConNewPasswordVisibility}
            >
              <img src={showConNewPassword ? EyeIcon : hideEyeIcon} alt="" />
            </div>
          </div>

          <div className="buttonCredWrapper">
            <button type="submit">Save</button>
            <button onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </>
  );
}

export default CredentialsForm;
