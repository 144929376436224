import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import Logo from "../../../assets/png/Logo.png";
import { ReactComponent as HamBurgerIcon } from "../../../assets/svg/HamBurgerIcon.svg";
import { ReactComponent as HomeIcon } from "../../../assets/svg/HomeIcon.svg";
import { ReactComponent as MeetingIcon } from "../../../assets/svg/MeetingIcon.svg";
import { ReactComponent as Coaches } from "../../../assets/svg/Coaches.svg";
import { ReactComponent as EventActive } from "../../../assets/svg/event_icon_white.svg";
import { ReactComponent as PolicyActive } from "../../../assets/svg/policyactive.svg";
import { ReactComponent as EventInactive } from "../../../assets/svg/event_icon_black.svg";
import "./MSideBar.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import ChatApp from "../../../ChatApp";

const MSideBar = ({ isMenuOpen, toggleMenu }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [activeLink, setActiveLink] = useState("");
  const [showChatApp, setShowChatApp] = useState(false);

  useEffect(() => {
    const currentPath = window.location.pathname;
    setActiveLink(currentPath);
  });

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });
  const getFileUrl = `${window.env_url}/v1/files/`;

  const appLogo = getFileUrl + localStorage.getItem("adminImage");
  const appName = localStorage.getItem("appname");

  return createPortal(
    <div className={`sidebarContainer ${isMenuOpen ? "menuOpen" : ""}`}>
      <div id="svgContainer">
        <CancelRoundedIcon sx={{ fill: "white" }} onClick={toggleMenu} />
      </div>
      <div className="mSideBar-img-container">
        <img src={appLogo} alt="logo" />
      </div>
      <div className="whiteborder"></div>
      {/* <div className="hamBurgerIcon"></div> */}
      <div className="whiteborder"></div>
      <Link to="/home">
        <div
          className={
            activeLink === "/home" || activeLink === "/booking-schedule"
              ? "iconSection mv-active"
              : "iconSection inActive"
          }
        >
          <HomeIcon
            fill={
              activeLink === "/home" || activeLink === "/booking-schedule"
                ? "#3384b1"
                : "white"
            }
          />
          <p
            style={{
              color:
                activeLink === "/home" || activeLink === "/booking-schedule"
                  ? "#3384b1"
                  : "#fff",
            }}
          >
            Home
          </p>
        </div>
      </Link>
      <div className="whiteborder"></div>
      <Link to="/meeting-rooms">
        <div
          className={
            activeLink === "/meeting-rooms" ||
            activeLink === "/meeting-room-details"
              ? "iconSection mv-active"
              : "iconSection inActive"
          }
        >
          <MeetingIcon
            fill={
              activeLink === "/meeting-rooms" ||
              activeLink === "/meeting-room-details"
                ? "#3384b1"
                : "white"
            }
          />
          <p
            style={{
              color:
                activeLink === "/meeting-rooms" ||
                activeLink === "/meeting-room-details"
                  ? "#3384b1"
                  : "#fff",
            }}
          >
            Meeting Rooms
          </p>
        </div>
      </Link>
      <div className="whiteborder"></div>
      <Link to="/coaches">
        <div
          className={
            activeLink === "/coaches" || activeLink === "/coach-details"
              ? "iconSection mv-active"
              : "iconSection inActive"
          }
        >
          <Coaches
            fill={
              activeLink === "/coaches" || activeLink === "/coach-details"
                ? "#3384b1"
                : "white"
            }
          />
          <p
            style={{
              color:
                activeLink === "/coaches" || activeLink === "/coach-details"
                  ? "#3384b1"
                  : "#fff",
            }}
          >
            Coaches
          </p>
        </div>
      </Link>
      <div className="whiteborder"></div>
      <Link to="/policy">
        <div
          className={
            activeLink === "/policy" || activeLink === "/meeting-room-details"
              ? "iconSection mv-active"
              : "iconSection inActive"
          }
        >
          <PolicyActive
            fill={
              activeLink === "/policy" || activeLink === "/meeting-room-details"
                ? "#3384b1"
                : "white"
            }
          />
          <p
            style={{
              color:
                activeLink === "/policy" ||
                activeLink === "/meeting-room-details"
                  ? "#3384b1"
                  : "#fff",
            }}
          >
            Policy
          </p>
        </div>
      </Link>
      <div className="whiteborder"></div>
      <Link to="/events">
        <div
          className={
            activeLink === "/events" || activeLink === "/meeting-room-details"
              ? "iconSection mv-active"
              : "iconSection inActive"
          }
        >
          <EventActive
            fill={
              activeLink === "/events" || activeLink === "/meeting-room-details"
                ? "#3384b1"
                : "white"
            }
          />
          <p
            style={{
              color:
                activeLink === "/events" ||
                activeLink === "/meeting-room-details"
                  ? "#3384b1"
                  : "#fff",
            }}
          >
            Events
          </p>
        </div>
      </Link>
      <div className="whiteborder"></div>
      <Link onClick={() => setShowChatApp(true)}>
        <div
          className={
            activeLink === "/chat" || activeLink === "/meeting-room-details"
              ? "iconSection mv-active"
              : "iconSection inActive"
          }
        >
          <FontAwesomeIcon color="white" icon={faComments} />
          <p>Chat</p>
        </div>
      </Link>
      {showChatApp && <ChatApp onClose={() => setShowChatApp(false)} />}

      <div className="whiteborder"></div>
    </div>,
    document.body
  );
};

export default MSideBar;



// width: 78%;
// font-size: 9px;
// display: block;
// margin-right: 0;
// height: auto;
// }