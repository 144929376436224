const SearchNavIcon = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        id="searchIcon-nav"
      >
        <path
          d="M15.0049 4.89493C12.8204 1.76203 8.49433 0.990471 5.3614 3.17487C2.22845 5.35938 1.45685 9.68542 3.64136 12.8183C5.49462 15.4762 8.7883 16.4161 11.6882 15.3119C11.6882 15.3119 11.8977 15.2328 12.0228 15.4125C12.7374 16.4373 14.8813 19.512 14.8813 19.512C15.4502 20.328 16.389 20.6655 17.1128 20.161L17.2372 20.0741C17.961 19.5696 17.9689 18.5719 17.3999 17.7559C17.3999 17.7559 15.2605 14.6877 14.5474 13.665C14.4162 13.4768 14.5628 13.3076 14.5628 13.3076C16.6014 10.9683 16.8582 7.5528 15.0049 4.89493ZM12.2505 13.0549C9.93557 14.669 6.73911 14.0988 5.12498 11.7839C3.51091 9.46901 4.08099 6.27253 6.3959 4.65845C8.71078 3.04432 11.9073 3.61446 13.5214 5.92939C15.1354 8.24429 14.5653 11.4407 12.2505 13.0549Z"
          fill="#3384b1"
        />
        <path
          d="M8.36185 11.9114C8.34555 12.0029 8.31099 12.0926 8.25717 12.1755C8.04186 12.5063 7.59903 12.6001 7.2681 12.3848C5.18803 11.0315 4.59681 8.23822 5.95015 6.15821C6.16546 5.82732 6.60829 5.73354 6.93923 5.94886C7.27015 6.16423 7.36385 6.60693 7.14857 6.93798C6.22524 8.35713 6.62862 10.2631 8.04777 11.1863C8.29597 11.3478 8.41076 11.6372 8.36185 11.9114Z"
          fill="#3384b1"
        />
      </svg>
    </>
  );
};

export default SearchNavIcon;
