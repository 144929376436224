import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { extractFirstValue, token } from "../../../utils/utilityFunctions";
import CoachBookingRow from "./CoachBookingRow";
import LoaderSpinner from "../../../utils/shimmer/LoaderSpinner";

const CoachBookingHistory = () => {
  const [bookingData, setBookingData] = useState([]);
  const [coachNames, setCoachNames] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchBookingData();
  }, []);

  const fetchBookingData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/coachbookings/byUser`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const bookings = response.data.body[0];
      setBookingData(bookings); // Update with the full array of booking data
      fetchCoachNames(bookings);
    } catch (error) {
      console.error(error.message);
    }
  };

  const fetchCoachNames = async (bookings) => {
    const coachNamesMap = {};
    try {
      for (const booking of bookings) {
        const coachId = booking.bookedCoachId;
        if (!coachNamesMap[coachId]) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/v1/coaches/${coachId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          coachNamesMap[coachId] =
            extractFirstValue(response.data.body[0]?.title, "|") ||
            "Unknown Coach";
        }
      }
      setCoachNames(coachNamesMap);
    } catch (error) {
      console.error("Failed to fetch coach names:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoaderSpinner />;
  }

  return (
    <>
      {bookingData.length === 0 ? (
        <h1 className="notFoundText">No Bookings Found!</h1>
      ) : (
        <div>
          <table className="bookingRequestTable" id="bookingDetailsId">
            <thead>
              <tr>
                <th>Coach Name</th>
                <th>Check In</th>
                <th>Check out</th>
                <th>Duration</th>
                <th>Skills</th>
                <th>Status</th>
                <th>Review Information</th>
              </tr>
            </thead>
            <tbody>
              {bookingData.map((item) => (
                <CoachBookingRow
                  key={item.id}
                  item={item}
                  coachName={coachNames[item.bookedCoachId]}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default CoachBookingHistory;
